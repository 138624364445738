import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class InformeService {

    saveNouInforme(nouInforme) {
        return axios.post(API_URL + 'informe', {
            ...nouInforme,
            idInforme: nouInforme.id
        }, {
            headers: authHeader()
        });
    }

    fillNouInforme(nouInforme) {
        return axios.post(API_URL + 'fill-informe', {
            ...nouInforme,
            idInforme: nouInforme.id
        }, {
            headers: authHeader()
        });
    }

    getInformes(idCentre) {
        return axios.post(API_URL + 'get-informes', {
            idCentre
        }, {
            headers: authHeader()
        });
    }

    getMeusCentres() {
        return axios.post(API_URL + 'get-meus-centres', {}, {
            headers: authHeader()
        });
    }

    getInforme(idInforme) {
        return axios.post(API_URL + 'get-informe', {
            idInforme
        }, {
            headers: authHeader()
        });
    }

    saveInforme(informe) {
        return axios.post(API_URL + 'save-informe', {
            informe,
            idInforme: informe.id
        }, {
            headers: authHeader()
        });
    }
    enviarCCB(idInforme) {
        return axios.post(API_URL + 'enviar-ccb', {
            idInforme
        }, {
            headers: authHeader()
        });
    }
    eliminarInforme(idInforme) {
        return axios.post(API_URL + 'del-informe', {
            idInforme
        }, {
            headers: authHeader()
        });
    }
    getCentre(idCentre, idInforme = null) {
        return axios.post(API_URL + 'get-centre', {
            idCentre,
            idInforme
        }, {
            headers: authHeader()
        });
    }
    getInformeParsed(idInforme) {
        return axios.post(API_URL + 'process-informe', {
            idInforme
        }, {
            headers: authHeader()
        });
    }
    updateCompactacions(nouInforme) {
        return axios.post(API_URL + 'update-compactacions-informe', {
            ...nouInforme,
            idInforme: nouInforme.id
        }, {
            headers: authHeader()
        });
    }
    uploadFile(formData) {
        return axios.post(API_URL + 'upload-informe', formData, {
            headers: authHeader(true)
        });
    }
    async checkAuth(autoLogout = true) {
        try {
            await axios.post(API_URL + 'check-auth', {}, {
                headers: authHeader()
            });
            return true
        } catch (error) {
            if (error.response.status == 401 && autoLogout) {
                this.logoutSession()
                location.href = '/login'
            }
            return false
        }
    }
    logoutSession() {
        localStorage.removeItem('user')
        localStorage.removeItem('access_token')
    }
}

export default new InformeService();