import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from 'primevue/config';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';

import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';

import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import { FontAwesomeIcon } from './plugins/font-awesome'

createApp(App)
    .use(router)
    .use(store)
    .use(PrimeVue)
    .use(ToastService)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component('Accordion', Accordion)
    .component('AccordionTab', AccordionTab)
    .component('AutoComplete', AutoComplete)
    .component('Breadcrumb', Breadcrumb)
    .component('Button', Button)
    .component('Calendar', Calendar)
    .component('Card', Card)
    .component('Carousel', Carousel)
    .component('Checkbox', Checkbox)
    .component('Chips', Chips)
    .component('ColorPicker', ColorPicker)
    .component('Column', Column)
    .component('ContextMenu', ContextMenu)
    .component('DataTable', DataTable)
    .component('DataView', DataView)
    .component('DataViewLayoutOptions', DataViewLayoutOptions)
    .component('Dialog', Dialog)
    .component('Dropdown', Dropdown)
    .component('Fieldset', Fieldset)
    .component('FileUpload', FileUpload)

    .component('InlineMessage', InlineMessage)
    .component('Inplace', Inplace)
    .component('InputMask', InputMask)
    .component('InputNumber', InputNumber)
    .component('InputSwitch', InputSwitch)
    .component('InputText', InputText)
    .component('Galleria', Galleria)
    .component('Listbox', Listbox)
    .component('MegaMenu', MegaMenu)
    .component('Menu', Menu)
    .component('Menubar', Menubar)
    .component('Message', Message)
    .component('MultiSelect', MultiSelect)
    .component('OrderList', OrderList)
    .component('OrganizationChart', OrganizationChart)
    .component('OverlayPanel', OverlayPanel)
    .component('Paginator', Paginator)
    .component('Panel', Panel)
    .component('PanelMenu', PanelMenu)
    .component('Password', Password)
    .component('PickList', PickList)
    .component('ProgressBar', ProgressBar)
    .component('RadioButton', RadioButton)
    .component('Rating', Rating)
    .component('SelectButton', SelectButton)
    .component('Slider', Slider)
    .component('Sidebar', Sidebar)
    .component('SplitButton', SplitButton)
    .component('Steps', Steps)
    .component('TabMenu', TabMenu)
    .component('TabView', TabView)
    .component('TabPanel', TabPanel)
    .component('Textarea', Textarea)
    .component('TieredMenu', TieredMenu)
    .component('Toast', Toast)
    .component('Toolbar', Toolbar)
    .component('ToggleButton', ToggleButton)
    .component('Tree', Tree)
    .component('TreeTable', TreeTable)
    .component('TriStateCheckbox', TriStateCheckbox)
    .mount("#app")