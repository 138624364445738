import {
  createWebHistory,
  createRouter
} from "vue-router";
import Login from "@/components/Login.vue";
const LlistatInformes = () => import("@/components/LlistatInformes.vue")
const Centres = () => import("@/components/Centres.vue")
const Informe = () => import("@/components/Informe.vue")
const BoardUser = () => import("@/components/BoardUser.vue")

const routes = [{
    path: "/",
    name: "home",
    component: LlistatInformes,
  },
  {
    path: "/informes/:centre?",
    name: "informes",
    component: LlistatInformes,
  },
  {
    path: "/centres",
    name: "centres",
    component: Centres,
  },
  {
    path: "/informe/:id",
    name: "informe",
    component: Informe,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;