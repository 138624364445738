<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <a href="/" class="navbar-brand">GesBeques</a>
      <div class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link to="/" class="nav-link" v-if="!currentUser">
            <font-awesome-icon icon="home" /> Inici
          </router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="currentUser" to="/informes" class="nav-link">
            <font-awesome-icon icon="file" /> Informes
          </router-link>
        </li>
      </div>

      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" />
          </router-link>
        </li>
      </div>

      <div v-if="currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" /> Sortir
          </a>
        </li>
      </div>
    </nav>

    <div class="container-fluid">
      <router-view />
    </div>
  </div>
</template>

<script>
import InformeService from "@/services/informes.service.js";

export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_MODERATOR");
      }

      return false;
    },
  },
  async mounted() {
    const publicPages = ["/login"];
    const PATH = window.location.pathname;
    if (publicPages.includes(PATH)) {
      InformeService.logoutSession();
      localStorage.removeItem("user");
      return;
    }

    const resAuth = await InformeService.checkAuth(false);
    const hasUser = localStorage.getItem("user");
    const loggedIn = resAuth && hasUser;

    if (!loggedIn) {
      InformeService.logoutSession();
      location.href = "/login";
      return;
    }

    // // trying to access a restricted page + not logged in
    // // redirect to login page
    // if (authRequired && !loggedIn) {
    //     next('/login');
    // } else {
    //     // await informesService.checkAuth()
    //     next();
    // }
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
